import React, { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { OutageCenterPage } from '../../components/outage';
import { navigate, Router } from '@reach/router';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import { OutageMapNotificationContextProvider } from '../../providers/OutageMapNotificationProvider';
import Box from '@material-ui/core/Box';
import OutageMapNotifications from '../../components/outage-map-notifications/OutageMapNotifications';
import BroadcastOutageCommunication from '../../components/outage/outage-center/broadcast-outage-communication/BroadcastOutageCommunication';
import { isNativeMobileWebView } from '../../util/mobile';

const OutagesMobileApp: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isNativeMobileWebView()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      void navigate(ROUTES.OUTAGES);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('OUTAGES')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <OutageMapNotificationContextProvider>
        <Router basepath="/">
          <OutageCenterPage
            isNativeMobileAppPage={true}
            path={ROUTES.OUTAGES_MOBILE_APP}
          />
        </Router>
        <Box id="outage-global-messages-container">
          <BroadcastOutageCommunication />
        </Box>
        <Box id="outage-notification-messages-container">
          <OutageMapNotifications />
        </Box>
      </OutageMapNotificationContextProvider>
    </>
  );
};

export default OutagesMobileApp;
